<template>
  <div class="WorkflowOwners">
    <div>
      <div class="WorkflowOwners__owner-list">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              fab
              dark
              color="secondary"
              depressed
              x-small
              v-on="on"
              v-bind="attrs"
              outlined
              @click.stop="handleShowWorkflowOwners"
            >
              <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>
            Add/Remove owners
          </span>
        </v-tooltip>

        <v-tooltip
          bottom
          v-for="owner in renderedOwners"
          :key="owner.id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="teal"
              size="32"
              v-on="on"
              v-bind="attrs"
              outlined
              class="WorkflowOwners__avatar-image"
            >
              <span class="white--text text-body-2">
                {{ owner.avatarText }}
              </span>
            </v-avatar>
          </template>
          <span>{{ owner.email }}</span>
        </v-tooltip>
        <span v-if="userCountLeft > 0">
          +{{ userCountLeft }}
        </span>
      </div>
    </div>

    <workflow-owners-modal
      v-if="showDialog"
      v-model="showDialog"
      :workflow="workflow"
    />

  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import WorkflowOwnersModal from './modals/workflow-owners-modal/workflow-owners-modal.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: "WorkflowOwners",
  components: {
    WorkflowOwnersModal,
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      customerUsers: 'CUSTOMER_USERS',
    }),
    ownerEmails() {
      if (this.workflow.users) {
        return this.workflow.users.map(owner => owner.email);
      }

      return [];
    },
    mappedUsers() {
      return this.customerUsers.map(user => ({
        ...user,
        selected: this.ownerEmails.includes(user.email),
      }))
    },
    renderedOwners() {
      let result = [];
      const selectedUsers = this.mappedUsers.filter(user => user.selected);
      const loggedInUserIndex = selectedUsers.findIndex(user => user.id === this.user.id);
      const loggedInUser = selectedUsers.splice(loggedInUserIndex, 1)[0];
      const numberOfSelectedToShow = loggedInUser ? 2 : 3;

      if (selectedUsers.length > numberOfSelectedToShow) {
        result = [
          ...selectedUsers.slice(0, numberOfSelectedToShow - 1),
          { email: `+${selectedUsers.length - numberOfSelectedToShow}`, id: "id" },
        ];
      } else {
        result = [...selectedUsers];
      }

      if (loggedInUser) {
        result = [loggedInUser, ...result];
      }

      return result.map(item => ({
        ...item,
        avatarText: this.extractAvatarText(item.email),
      }));
    },
    userCountLeft() {
      return this.mappedUsers.filter(user => user.selected).length - 3;
    }
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      disabled: false,
    };
  },
  methods: {
    extractAvatarText(email) {
      const name = email.split("@")[0];
      const names = name.split(".");

      return names[1]
        ? `${names[0].substr(0, 1)}${names[1].substr(0, 1)}`
        : `${names[0].substr(0, 2)}`;
    },
    handleShowWorkflowOwners() {
      this.showDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './workflow-owners';
</style>
