<template>
  <div class="AutomationList">
    <automation-list-item
      v-for="workflow in automationWorkflows"
      class="AutomationList__item"
      :key="workflow.id"
      :workflow="workflow"
      :edit-workflow-loading="editWorkflowLoading"
      @update-workflow="handleWorkflowUpdate"
      @copy-workflow="handleCopyWorkflow"
      @export-workflow="handleExportWorkflow"
      @delete-workflow="handleDeleteWorkflow"
      @show-history="handleShowHistory"
      @go-to-workflow="handleGoToWorkflow"
    />
  </div>
</template>

<script>
import AutomationListItem from './automation-list-item/automation-list-item.vue';

export default {
  name: 'AutomationList',
  components: {
    AutomationListItem,
  },
  props: {
    automationWorkflows: {
      type: Array,
      default: () => [],
    },
    editWorkflowLoading: {},
  },
  methods: {
    handleWorkflowUpdate(workflow) {
      this.$emit('update-workflow', workflow);
    },
    handleCopyWorkflow(workflow) {
      this.$emit('copy-workflow', workflow);
    },
    handleDeleteWorkflow(workflow) {
      this.$emit('delete-workflow', workflow);
    },
    handleShowHistory(workflow) {
      this.$emit('show-history', workflow);
    },
    handleGoToWorkflow(workflow) {
      this.$emit('go-to-workflow', workflow);
    },
    handleExportWorkflow(workflowId) {
      this.$emit('export-workflow', workflowId);
    },
  },
};
</script>

<style scoped lang="scss">
@import './automation-list';
</style>
