<template>
  <v-row class="AutomationGrid" dense>
    <v-col
      v-for="workflow in automationWorkflows"
      :key="workflow.id"
      lg="3"
      md="4"
      xl="2"
    >
      <automation-grid-item
        class="AutomationGrid__item"
        :workflow="workflow"
        :edit-workflow-loading="editWorkflowLoading"
        @update-workflow="handleWorkflowUpdate"
        @copy-workflow="handleCopyWorkflow"
        @export-workflow="handleExportWorkflow"
        @delete-workflow="handleDeleteWorkflow"
        @show-history="handleShowHistory"
        @go-to-workflow="handleGoToWorkflow"
      />
    </v-col>
  </v-row>
</template>

<script>
import AutomationGridItem from './automation-grid-item/automation-grid-item.vue';

export default {
  name: 'AutomationGrid',
  components: {
    AutomationGridItem,
  },
  props: {
    automationWorkflows: {
      type: Array,
      default: () => [],
    },
    editWorkflowLoading: {},
  },
  methods: {
    handleWorkflowUpdate(workflow) {
      this.$emit('update-workflow', workflow);
    },
    handleCopyWorkflow(workflow) {
      this.$emit('copy-workflow', workflow);
    },
    handleDeleteWorkflow(workflow) {
      this.$emit('delete-workflow', workflow);
    },
    handleShowHistory(workflow) {
      this.$emit('show-history', workflow);
    },
    handleGoToWorkflow(workflow) {
      this.$emit('go-to-workflow', workflow);
    },
    handleExportWorkflow(workflowId) {
      this.$emit('export-workflow', workflowId);
    },
  },
};
</script>

<style scoped lang="scss">
@import './automation-grid';
</style>
