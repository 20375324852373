<template>
  <v-row class="d-flex mb-2 justify-space-between">
    <v-col cols="12" md="6" lg="4">
      <form class="d-flex align-center" @submit.prevent="handleSubmit">
        <v-text-field
          outlined
          dense
          label="Search by name"
          hide-details="auto"
          v-model="search"
        ></v-text-field>

        <v-btn type="submit" @click="handleSubmit">Search</v-btn>
      </form>
    </v-col>

    <v-col cols="1" class="d-flex align-content-end self-end">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="buttonColor"
            icon
            v-bind="attrs"
            v-on="on"
            @click="handleArchiveChange"
          >
            <v-icon>mdi-archive</v-icon>
          </v-btn>
        </template>
        <span class="toggle">Toggle Archive View</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WorkflowSearchForm',
  data() {
    return {
      search: '',
      showingArchived: false,
    };
  },
  mounted() {
    this.search = this.$route.query.search ?? '';
    this.showingArchived = !!(this.$route.query.archived ?? '');
  },
  computed: {
    archived() {
      return this.showingArchived ? 'archived' : 'active';
    },
    buttonColor() {
      return this.showingArchived ? '' : 'primary';
    },
  },
  methods: {
    handleArchiveChange() {
      this.showingArchived = !this.showingArchived;
      this.handleSubmit();
    },
    handleSubmit() {
      this.$emit('filter-update', {
        search: this.search,
        archived: this.archived,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  width: fit-content;
}
</style>
