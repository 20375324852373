<template>
  <v-card
    tile
    flat
    outlined
    class="AutomationListItem"
    @click="handleWorkflowClick"
  >
    <v-card-text class="AutomationListItem__body">
      <v-row>
        <v-col cols="3">
          <p class="text-subtitle-1 ma-0">{{ workflow.name }}</p>
          <p class="text-caption ma-0 text-truncate">
            {{ workflow.description || '--' }}
          </p>
        </v-col>

        <v-col cols="2" align-self="center">
          <span v-for="(connector, index) in connectors" :key="index">
            <ActionIcon :action="connector" />
          </span>
        </v-col>

        <v-col align-self="center" cols="3">
          <workflow-owners :workflow="workflow" />
        </v-col>

        <v-col align-self="center" cols="auto">
          <v-switch
            @click.native.stop
            v-model="workflowStatus"
            :loading="editLoading"
          ></v-switch>
        </v-col>

        <v-col align-self="center" cols="auto">
          <v-chip small :color="statusColor" class="AutomationListItem__status">
            {{ status }}
          </v-chip>
        </v-col>

        <v-col cols="auto" align-self="center" class="ml-auto">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs">
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                @click="item.action(workflow)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon small class="pr-2">
                      {{ item.icon }}
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import WorkflowOwners from '@/components/workflow-owners/workflow-owners.vue';
import extractConnectors from '@/util/get-connectors.js';
import ActionIcon from '@/components/action-icon/action-icon.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'AutomationListItem',
  components: {
    WorkflowOwners,
    ActionIcon,
  },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    editWorkflowLoading: {},
  },
  computed: {
    ...customerGetters({ customer: 'CUSTOMER' }),
    connectors() {
      if (this.workflow.connectors) {
        return extractConnectors(this.workflow.connectors);
      }

      return [];
    },
    workflowStatus: {
      get() {
        return Boolean(this.workflow.enabled);
      },
      set(value) {
        this.$emit('update-workflow', { id: this.workflow.id, enabled: value });
      },
    },
    status() {
      return this.workflow.enabled ? 'enabled' : 'disabled';
    },
    statusColor() {
      return this.workflow.enabled ? 'secondary' : '';
    },
    editLoading() {
      return this.editWorkflowLoading(this.workflow.id);
    },
    sh_admin() {
      return this.customer.customer_id === 0;
    },
    isArchived() {
      return this.workflow.dateArchived;
    },
    menuItems() {
      const options = [
        {
          title: 'Show history',
          action: this.showWorkflowHistory,
          icon: 'mdi-history',
        },
        {
          title: 'Copy Workflow',
          action: this.copyWorkflow,
          icon: 'mdi-content-copy',
        },
        {
          title: `${!this.isArchived ? 'Archive' : 'Unarchive'} Workflow`,
          action: this.toggleArchiveWorkflow,
          icon: 'mdi-archive',
        },
        {
          title: 'Delete',
          action: this.deleteWorkflow,
          icon: 'mdi-delete',
        },
      ];
      const exportOptions = {
        title: 'Export',
        action: this.exportWorkflow,
        icon: 'mdi-export',
      };
      if (this.sh_admin) {
        options.splice(1, 0, exportOptions);
      }
      return options;
    },
  },
  methods: {
    copyWorkflow(workflow) {
      this.$emit('copy-workflow', workflow);
    },
    toggleArchiveWorkflow(workflow) {
      const data = { id: workflow.id, dateArchived: null };
      if (!this.isArchived) {
        data.dateArchived = new Date();
      }
      this.$emit('update-workflow', data);
    },
    deleteWorkflow(workflow) {
      this.$emit('delete-workflow', workflow);
    },
    showWorkflowHistory(workflow) {
      this.$emit('show-history', workflow);
    },
    handleWorkflowClick() {
      this.$emit('go-to-workflow', this.workflow);
    },
    exportWorkflow(workflow) {
      this.$emit('export-workflow', workflow.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './automation-list-item.scss';
</style>
