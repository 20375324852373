<template>
  <v-dialog v-model="modalOpen" max-width="630px" class="WorkflowOwnersModal">
    <v-card outlined>
      <v-card-title class="WorkflowOwnersModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            Add/Remove Workflow Owners
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="WorkflowOwnersModal__content">
        <v-row class="WorkflowOwnersModal__list">
          <v-col cols="auto" v-for="user in users" :key="user.id">
            <v-checkbox
              :label="user.email"
              v-model="user.selected"
              :disabled="
                (!user.selected && selectedUsersLength >= 5) || loading
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn plain :disabled="loading" @click="onCancel">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="loading"
              :loading="loading"
              @click="handleSave"
            >
              save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: workflowsGetters,
  mapActions: workflowsActions,
} = createNamespacedHelpers('workflows/list');

export default {
  name: 'WorkflowOwnersModal',
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    ...workflowsActions(['setWorkflowOwners']),
    close() {
      this.modalOpen = false;
    },
    onCancel() {
      this.close();
    },
    async handleSave() {
      await this.setWorkflowOwners({
        id: this.workflow.id,
        owners: this.selectedUsers,
      });
      this.modalOpen = false;
    },
  },
  computed: {
    ...customerGetters({
      customerUsers: 'CUSTOMER_USERS',
    }),
    ...workflowsGetters({
      workflowOwnerLoading: 'WORKFLOW_OWNER_LOADING',
    }),
    loading() {
      return this.workflowOwnerLoading(this.workflow.id);
    },
    ownerEmails() {
      if (this.workflow && this.workflow.users) {
        return this.workflow.users.map((owner) => owner.email);
      }
      return [];
    },
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    selectedUsers() {
      return this.users.filter((user) => user.selected);
    },
    selectedUsersLength() {
      return this.selectedUsers.length;
    },
  },
  mounted() {
    this.users = this.customerUsers.map((user) => ({
      ...user,
      selected: this.ownerEmails.includes(user.email),
    }));
  },
};
</script>

<style lang="scss" scoped>
@import './workflow-owners-modal';
</style>
