<template>
  <div class="Automation">
    <v-row class="Automation__filter-row">
      <v-col cols="auto" class="mr-auto">
        <v-btn-toggle
          v-model="workflowView"
          mandatory
          dense
          tile
          color="primary"
          group
        >
          <v-btn text value="list">
            <v-icon :left="isList"> mdi-view-list </v-icon>
            <v-expand-x-transition>
              <span v-if="isList">List</span>
            </v-expand-x-transition>
          </v-btn>
          <v-btn text value="grid">
            <v-icon :left="isGrid"> mdi-view-grid </v-icon>
            <v-expand-x-transition>
              <span v-if="isGrid">Grid</span>
            </v-expand-x-transition>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y open-on-hover bottom right>
          <template #activator="{ on, attrs }">
            <v-btn small color="primary" v-on="on" v-bind="attrs">
              + Add new Bot
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class=""
              v-for="(item, index) in botCreationOptions"
              :key="index"
              @click="handleOptionSelection(item.value)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
            <v-file-input
              truncate-length="10"
              dense
              @change="handleWorkflowImport"
              ref="fileSelect"
              class="d-none"
              accept=".json"
              single-line
            ></v-file-input>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <div class="Automation__content">
      <WorkflowToolbar @filter-update="handleFilter" />

      <div v-if="getAutomationWorkflowsLoading" class="Automation__loading">
        <div>
          <v-progress-circular
            :size="150"
            :width="3"
            color="primary"
            indeterminate
          />
          <p class="text-center py-4">Loading...</p>
        </div>
      </div>

      <template v-else>
        <automation-list
          v-if="isList"
          :automation-workflows="automationWorkflows"
          :edit-workflow-loading="editAutomationWorkflowLoading"
          @update-workflow="handleWorkflowUpdate"
          @copy-workflow="handleCopyWorkflow"
          @export-workflow="exportWorkflow"
          @delete-workflow="handleDeleteWorkflow"
          @show-history="handleShowHistory"
          @go-to-workflow="handleGoToWorkflow"
        />

        <automation-grid
          v-if="isGrid"
          :automation-workflows="automationWorkflows"
          :edit-workflow-loading="editAutomationWorkflowLoading"
          @update-workflow="handleWorkflowUpdate"
          @copy-workflow="handleCopyWorkflow"
          @export-workflow="exportWorkflow"
          @delete-workflow="handleDeleteWorkflow"
          @show-history="handleShowHistory"
          @go-to-workflow="handleGoToWorkflow"
        />
      </template>

      <v-pagination
        class="mt-2"
        v-model="page"
        :length="totalPages"
        :total-visible="5"
      ></v-pagination>
    </div>

    <action-prompt
      v-model="showDeleteWorkflowModal"
      v-if="showDeleteWorkflowModal"
      action-text="Delete"
      :action="deleteWorkflowMethod"
      :loading="deleteAutomationWorkflowLoading"
      header="Delete Workflow"
      body="Are you sure you want to delete this workflow?"
      action-type="error"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import AutomationList from '@/components/automation-workflows/automation-list/automation-list.vue';
import AutomationGrid from '@/components/automation-workflows/automation-grid/automation-grid.vue';
import WorkflowToolbar from './toolbar.vue';

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers('customer');
const { mapGetters: workflowsGetters, mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/list');
const { mapMutations: workflowsMutations } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'Automation',
  components: {
    ActionPrompt,
    AutomationList,
    AutomationGrid,
    WorkflowToolbar,
  },
  data() {
    return {
      workflowView: 'list',
      showDeleteWorkflowModal: false,
      workflowToEdit: null,
      page: 1,
    };
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchWorkflows({});
      this.getCustomerUsers();
    },
    page(newPage) {
      const query = { ...this.$route.query };
      if (newPage > 1) {
        query.page = newPage;
        this.$router.push({ query });
      }
      if (newPage === 1 && query.page) {
        this.$router.push({ query: { ...query, page: undefined } });
      }
      this.fetchWorkflows({ page: newPage, search: query.search });
    },
  },
  methods: {
    ...workflowsActions([
      'fetchWorkflows',
      'updateWorkflow',
      'deleteWorkflow',
      'copyWorkflow',
      'exportWorkflow',
    ]),
    ...customerActions(['getCustomerUsers']),
    ...workflowsMutations({ setWorkflow: 'SET_AUTOMATION_WORKFLOW' }),
    handleOptionSelection(type) {
      if (type === 'blank') {
        this.$router.push({ path: '/workflows/new/builder' });
      } else if (type === 'import') {
        this.$refs.fileSelect.$refs.input.click();
      }
    },
    handleWorkflowUpdate(updatedFields) {
      const data = {
        customer_id: this.customer.customer_id,
        workflow_id: updatedFields.id,
        updatedFields,
      };

      this.updateWorkflow(data);
    },
    handleCopyWorkflow(workflow) {
      this.copyWorkflow(workflow);
    },
    handleDeleteWorkflow(workflow) {
      this.showDeleteWorkflowModal = true;
      this.workflowToEdit = workflow;
    },
    handleShowHistory({ id }) {
      this.$router.push({ path: `/workflows/${id}/history` });
    },
    async deleteWorkflowMethod() {
      await this.deleteWorkflow(this.workflowToEdit);
      this.workflowToEdit = null;
      this.showDeleteWorkflowModal = false;
    },
    handleGoToWorkflow({ id }) {
      this.$router.push({ path: `/workflows/${id}/builder` });
    },
    handleWorkflowImport(file) {
      const reader = new FileReader();
      if (file) {
        reader.readAsText(file);
      }
      reader.onload = async () => {
        const workflow = { ...JSON.parse(reader.result), id: 'new' };
        await this.setWorkflow(workflow);
        this.$router.push({ path: '/workflows/new/builder' });
      };
    },
    handleFilter({ search, archived }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: undefined,
          search: search || undefined,
          archived: archived || undefined,
        },
      });
      this.page = 1;
      this.fetchWorkflows({ search, archived });
    },
  },
  computed: {
    ...workflowsGetters({
      automationWorkflows: 'AUTOMATION_WORKFLOWS',
      workflowsCount: 'AUTOMATION_WORKFLOWS_COUNT',
      getAutomationWorkflowsLoading: 'FETCH_AUTOMATION_WORKFLOWS_LOADING',
      editAutomationWorkflowLoading: 'EDIT_AUTOMATION_WORKFLOW_LOADING',
      deleteAutomationWorkflowLoading: 'DELETE_AUTOMATION_WORKFLOW_LOADING',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    isList() {
      return this.workflowView === 'list';
    },
    isGrid() {
      return this.workflowView === 'grid';
    },
    sh_admin() {
      return this.customer.customer_id === 0;
    },
    botCreationOptions() {
      const options = [
        {
          name: 'From Blank',
          value: 'blank',
        },
        {
          name: 'From Template',
          value: 'template',
        },
      ];
      const importOption = {
        name: 'Import Workflow',
        value: 'import',
      };
      if (this.sh_admin) {
        options.splice(1, 0, importOption);
      }

      return options;
    },
    totalPages() {
      const defaultLimit = 30;
      return Math.ceil(this.workflowsCount / defaultLimit);
    },
  },
  mounted() {
    const page = this.$route.query.page;
    this.page = page ? Number(page) : 1;
    this.fetchWorkflows({
      search: this.$route.query.search ?? '',
      archived: this.$route.query.archived ?? '',
      page: this.page,
    });
    this.getCustomerUsers();
  },
};
</script>

<style lang="scss" scoped>
@import './automation';
</style>
